import { StatusChip } from './StatusChip'
import {
  getSportByName,
  getTeamAbbreviation,
} from '@/constants/countries-leagues'
import { formatDate } from '@/utils/dates'
import { calculateCoefficient, statusColors } from './data'
import { getSinglePossibleWin } from '@/utils/bets'
import { BsvIcon, LayersThree } from '@/icons/index'

export const BetIdRenderer = (props) => {
  const data = props.data

  return (
    <div className="text-sm font-medium  cursor-pointer" title={data?.betID}>
      {data?.betID}
    </div>
  )
}

export const SportRenderer = (props) => {
  const data = props?.data
  if (!data) {
    return <></>
  }
  let sport = ''
  if (data?.betSlips) {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
        <div className="bg-[#15191B] rounded-lg p-2.5">
          <LayersThree className="w-5 h-5" />
        </div>
        <div className="p-2">
          <span className="capitalize ">{`${data.betType} (${data.betSlips.length})`}</span>
        </div>
      </div>
    )
  } else {
    sport = data?.match?.sport
  }

  const currentSport = getSportByName(sport)
  return (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
      <div className={`bg-[#15191B] rounded-lg p-2.5 relative `}>
        <currentSport.logo className="w-5 h-5" />
      </div>
      <div className="p-2 ">
        <span>{sport}</span>
      </div>
    </div>
  )
}

export const SportMobileRenderer = (props) => {
  const data = props.data

  if (data?.betSlips) {
    return (
      <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%]">
        <div className="bg-[#15191B] rounded-lg p-2">
          <LayersThree className="w-5 h-5" />
        </div>
        <div className="p-2">
          <span className="capitalize ">{`${data.betType} (${data.betSlips.length})`}</span>
        </div>
      </div>
    )
  }

  const currentSport = getSportByName(data?.match?.sport)

  return (
    <div className="text-sm font-medium capitalize flex gap-x-2 w-[100%] ">
      <div className={`bg-[#15191B] rounded-lg p-2 relative`}>
        <currentSport.logo className="w-5 h-5" />
      </div>
      <div className="text-sm font-medium my-auto text-center">
        {data?.future ? (
          <div>{data.future.slice(0, 4) + '...'}</div>
        ) : (
          <div>
            {getTeamAbbreviation(data?.match?.league, data?.match?.home_team)}{' '}
            <> - </>
            {getTeamAbbreviation(data?.match?.league, data?.match?.away_team)}
          </div>
        )}
      </div>
    </div>
  )
}

export const ResultRenderer = (props) => {
  const data = props.data

  if (data?.status === '') {
    return <></>
  }

  const status =
    data?.status === 'active'
      ? 'active'
      : data?.grade === 'win'
      ? 'win'
      : data?.grade === 'loss'
      ? 'lose'
      : data?.grade === 'refund'
      ? 'push'
      : ''
  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}
export const PaymentStatusRenderer = (props) => {
  const data = props.data
  if (data?.status === '') {
    return <></>
  }
  const status =
    data?.status === 'active'
      ? 'active'
      : data?.status === 'completed' || data?.status === 'graded'
      ? 'completed'
      : 'NA'

  return (
    <StatusChip
      status={status}
      className={`${statusColors[status]} capitalize`}
    />
  )
}

export const BetRenderer = (props) => {
  const { usdToBsv, bsvToUsd, data } = props
  const amountByBetType = data?.betSlips ? data?.price : data?.betPrice
  const bet = data?.isBsvBet
    ? bsvToUsd(Number(amountByBetType)).toFixed(4)
    : amountByBetType
  const bsv = data?.isBsvBet
    ? Number(amountByBetType).toFixed(4)
    : usdToBsv(amountByBetType).toFixed(4)

  return (
    <div className="text-sm font-medium space-y-2 ">
      <div className="flex gap-x-2 items-center relative">
        <BsvIcon className="w-4 h-4" /> {bsv}
      </div>
      <div>$ {bet}</div>
    </div>
  )
}

export const MatchRenderer = (props) => {
  const data = props.data

  if (data?.betSlips) {
    return <></>
  }

  return (
    <div className="text-sm font-medium space-y-2 text-center">
      {data?.future ? (
        <div className="whitespace-pre-wrap">{data.future}</div>
      ) : (
        <>
          <div>{data?.match?.home_team}</div>
          <div>{data?.match?.away_team}</div>
        </>
      )}
    </div>
  )
}

export const DateRenderer = (props) => {
  const data = props?.data
  if (data?.betSlips || !data) {
    return <></>
  }
  return (
    <div className="text-sm font-medium space-y-2 ">
      <div>{formatDate(data?.placedAt, 'MMM DD, YYYY')}</div>
      <div>{formatDate(data?.placedAt, 'hh:mm:ss a')}</div>
    </div>
  )
}

export const WinRenderer = (props) => {
  const { usdToBsv, bsvToUsd, data } = props
  if (data?.price === '' || data?.refunded || !data) {
    return <></>
  }

  if (data?.betSlips) {
    return (
      <div className="text-sm font-medium space-y-2 ">
        {data?.status !== 'active' && (
          <div className="flex gap-x-2 items-center relative">
            <BsvIcon className="w-4 h-4" />
            {data?.grade === 'win'
              ? `${
                  data?.isBsvBet
                    ? parseFloat(data.possibleWinPrice).toFixed(8)
                    : usdToBsv(parseFloat(data.possibleWinPrice))?.toFixed(8)
                }`
              : `-${
                  data?.isBsvBet
                    ? parseFloat(data?.price).toFixed(8)
                    : usdToBsv(parseFloat(data?.price))?.toFixed(8)
                }`}
          </div>
        )}
        <div>
          {data?.status !== 'active' && '$ '}
          {data?.status !== 'active' &&
            (data?.grade === 'win'
              ? `${
                  data?.isBsvBet
                    ? bsvToUsd(parseFloat(data.possibleWinPrice))?.toFixed(8)
                    : parseFloat(data.possibleWinPrice)?.toFixed(8)
                }`
              : `-${
                  data?.isBsvBet
                    ? bsvToUsd(parseFloat(data?.price))?.toFixed(8)
                    : parseFloat(data?.price)?.toFixed(8)
                }`)}
        </div>
      </div>
    )
  }

  return (
    <div className="text-sm font-medium space-y-2 ">
      {data?.status !== 'active' && (
        <div className="flex gap-x-2 items-center relative">
          <BsvIcon className="w-4 h-4" />
          {data?.grade === 'win'
            ? `${
                data?.isBsvBet
                  ? getSinglePossibleWin({
                      betPrice: data?.betPrice,
                      price: data?.price,
                    })?.toFixed(8)
                  : usdToBsv(
                      getSinglePossibleWin({
                        betPrice: data?.betPrice,
                        price: data?.price,
                      }),
                    )?.toFixed(8)
              }`
            : `-${
                data?.isBsvBet
                  ? (isNaN(data?.betPrice)
                      ? 0.0
                      : Number(data?.betPrice)
                    )?.toFixed(8)
                  : usdToBsv(
                      isNaN(data?.betPrice) ? 0.0 : Number(data?.betPrice),
                    )?.toFixed(8)
              }`}
        </div>
      )}
      <div>
        {data?.status !== 'active' && '$ '}
        {data?.status !== 'active' &&
          (data?.grade === 'win'
            ? `${
                data?.isBsvBet
                  ? bsvToUsd(
                      getSinglePossibleWin({
                        betPrice: data?.betPrice,
                        price: data?.price,
                      }),
                    )?.toFixed(8)
                  : getSinglePossibleWin({
                      betPrice: data?.betPrice,
                      price: data?.price,
                    })?.toFixed(8)
              }`
            : `-${
                data?.isBsvBet
                  ? bsvToUsd(Number(data?.betPrice))?.toFixed(8)
                  : (isNaN(data?.betPrice)
                      ? 0.0
                      : Number(data?.betPrice)
                    )?.toFixed(8)
              }`)}
      </div>
    </div>
  )
}

export const CoefRenderer = (props) => {
  const data = props.data

  if (data?.price === '') {
    return <></>
  }

  return (
    <StatusChip
      status={calculateCoefficient(data)}
      className={`${statusColors['grey']} capitalize`}
    />
  )
}
