import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { numberSort } from './data'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'
import authSelector from '@/redux/selectors/auth'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { FormattedMessage, useIntl } from 'react-intl'
import { firebasaGetBetsWithPaginate } from '@/firebase/utils'
import {
  BetRenderer,
  BetIdRenderer,
  SportRenderer,
  ResultRenderer,
  MatchRenderer,
  CoefRenderer,
  DateRenderer,
  WinRenderer,
  PaymentStatusRenderer,
  SportMobileRenderer,
} from './tableComponents'
import useExchangeRates from '@/hooks/useExchangeRates'

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([InfiniteRowModelModule])

const BetsTable = ({
  onIdPress,
  isHome = false,
  setAllBets,
  isUserBets,
  isMyBets,
}) => {
  const [lastVisible, setLastVisible] = useState(null)
  const lastVisibleRef = useRef(lastVisible)
  const { usdToBsv, bsvToUsd } = useExchangeRates()
  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { user, isAuthenticated } = useSelector(authSelector)
  const router = useRouter()
  const isAdmin = user?.roleType === 'admin'
  const isAdminBets =
    router.pathname.includes('bets') && isAdmin && isAuthenticated === true
  const intl = useIntl()

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }

  const sortModel = [
    { colId: 'placedAt', sort: 'asc' }, // Specify the column ID and sort order
  ]

  const columnDefs = [
    {
      headerName: intl.formatMessage({
        id: 'table.betId',
        defaultMessage: 'Bet ID',
      }),
      field: 'betID',
      suppressMovable: true,
      cellRenderer: BetIdRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 100,
      valueGetter: 'node.id',
      onCellClicked: onCellClicked,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.sport',
        defaultMessage: 'Sport',
      }),
      field: 'match.sport',
      suppressMovable: true,
      cellRenderer: SportRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 170,
      onCellClicked: onCellClicked,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.result',
        defaultMessage: 'Result',
      }),
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 110,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.match',
        defaultMessage: 'Match',
      }),
      field: 'match.home_team',
      suppressMovable: true,
      cellRenderer: MatchRenderer,
      sortable: false,
      unSortIcon: true,
      onCellClicked: onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.bet',
        defaultMessage: 'Bet',
      }),
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.date',
        defaultMessage: 'Date/Time',
      }),
      field: 'placedAt',
      suppressMovable: true,
      cellRenderer: DateRenderer,
      onCellClicked: onCellClicked,
      sortable: false,
      // sortingOrder: ['asc', 'desc'],
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.win',
        defaultMessage: 'Win',
      }),
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <WinRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.coef',
        defaultMessage: 'Coef',
      }),
      field: 'price',
      suppressMovable: true,
      cellRenderer: CoefRenderer,
      comparator: numberSort,
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 130,
    },
  ]
  const paymentStatusColumn = {
    headerName: 'Payment Status',
    field: 'status',
    suppressMovable: true,
    cellRenderer: PaymentStatusRenderer,
    sortable: false,
    unSortIcon: true,
    minWidth: 110,
  }
  const adminColumns =
    isAdmin && router.pathname.includes('admin/bets')
      ? [paymentStatusColumn]
      : []
  const columnDefinitions = [...columnDefs, ...adminColumns]
  const columnMobile = [
    {
      headerName: 'Event',
      field: 'match',
      suppressMovable: true,
      cellRenderer: SportMobileRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      onCellClicked: onCellClicked,

      minWidth: 150,
    },
    {
      headerName: 'Bet Amount',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      sortable: false,
    }
  }, [])

  useEffect(() => {
    lastVisibleRef.current = lastVisible
  }, [lastVisible])

  const fetchNextChunk = async (successCallback) => {
    const data = await firebasaGetBetsWithPaginate(
      lastVisibleRef.current,
      isUserBets,
    )
    setLastVisible(data.lastDoc)
    successCallback(data.bets, data.bets.length === 0 ? true : null)
  }

  const dataSource = {
    getRows: async (params) => {
      await fetchNextChunk((newData, lastRow) => {
        if (!lastRow) {
          if (newData.length < 40) {
            params.successCallback(newData, null)
          } else {
            params.successCallback(newData)
          }
          setAllBets((state) => [...state, ...newData])
        }
      })
    },
  }

  const getRowId = useCallback(function (params) {
    return params.data.betID
  }, [])

  return (
    <div
      className={`${
        isAdminBets || isMyBets ? 'h-[500px]' : 'h-[720px]'
      }  pb-[0.5] mx-auto my-8  ${
        isHome === true ? 'w-[100%]' : 'w-[95%] 2xl:w-[97%]'
      }`}
      // id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          columnDefs={sm ? columnMobile : columnDefinitions}
          rowHeight={70}
          defaultColDef={defaultColDef}
          animateRows={true}
          sortModel={sortModel}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444;">No Results Found</span>'
          }
          rowModelType="infinite"
          getRowId={getRowId}
          onGridReady={(params) =>
            params.api.setGridOption('datasource', dataSource)
          }
          cacheBlockSize={40}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={1}
          maxBlocksInCache={10}
        />
      </div>
    </div>
  )
}

export default BetsTable
