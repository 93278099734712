import { AgGridReact } from 'ag-grid-react'
import { useCallback, useMemo } from 'react'
import useExchangeRates from '@/hooks/useExchangeRates'
import {
  calculateTimeMultiplier,
  getExpireyDate,
  getAmountRenderer,
  UserRenderer,
  getRowId,
  dateFormatter,
} from '../prediction-market/User/predictionEventDetail/helper'

const getTimeMultiplierFormatter = (eventsData) => (params) => {
  const expiryDate = getExpireyDate(
    eventsData[params.data.market.id].expiryDate,
  )
  return calculateTimeMultiplier(params.value, expiryDate)
}

export default function BetsTable({ betsData, onBetIdPress, user }) {
  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }
  const { bsvToUsd } = useExchangeRates()

  const onCellClicked = ({ data }) => {
    const expiryDate = getExpireyDate(
      betsData.events[data.market.id].expiryDate,
    )
    const title = betsData.events[data.market.id].title
    onBetIdPress(data, expiryDate, title)
  }

  const colDefs = useMemo(() => {
    const defCols = [
      {
        field: 'answer',
        cellStyle: ({ value }) => {
          return {
            justifyContent: 'left',
            fontWeight: 700,
          }
        },
      },
      {
        field: 'amount',
        cellRenderer: getAmountRenderer(bsvToUsd),
        tooltipValueGetter: (params) => params.value,
      },
      {
        field: 'createdAt',
        headerName: 'Time Multiplier',
        valueFormatter: getTimeMultiplierFormatter(betsData.events),
        width: 140,
      },
    ]
    const columns = [
      {
        field: 'createdAt',
        valueFormatter: dateFormatter,
        cellDataType: ['dateString'],
        width: 140,
      },
    ]
    if (!user) {
      columns.push(
        {
          field: 'user',
          cellRenderer: UserRenderer,
        },
        ...defCols,
      )
    } else {
      columns.push(...defCols)
    }

    return columns
  }, [bsvToUsd, betsData, user])

  const getRowHeight = useCallback((params) => {
    return params.data.rowHeight
  }, [])

  return (
    <div className="h-[500px] pb-[0.5] mx-auto my-8 w-[95%] 2xl:w-[97%]">
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          rowData={betsData.bets}
          columnDefs={colDefs}
          getRowId={getRowId}
          onRowClicked={onCellClicked}
          defaultColDef={{
            headerClass: 'text-center',
            cellStyle: () => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
            }),
          }}
          getRowHeight={getRowHeight}
          animateRows={true}
          overlayLoadingTemplate={'<span class="plinko-loader"></span>'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444;">No Results Found</span>'
          }
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
    </div>
  )
}
