import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import useExchangeRates from '@/hooks/useExchangeRates'
import {
  AnonUser,
  BadgeBlue,
  BadgeGray,
  BadgePink,
  BadgeYellow,
} from '@/icons/index'
import CustomImage from '@/utils/customImage'

const gridStyle = {
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
}
const images = {
  0: <BadgeYellow className="w-10 h-10" />,
  1: <BadgePink className="w-10 h-10" />,
  2: <BadgeBlue className="w-10 h-10" />,
  3: <BadgeGray className="w-10 h-10" />,
}

const LeaderBoardTable = ({
  leaderInfo,
  userDetails,
  setModelData,
  setSlipModelOpen,
  isHome = false,
}) => {
  const { usdToBsv, bsvToUsd } = useExchangeRates()
  const RankRenderer = (props) => {
    const { rowIndex } = props
    let icon = ''
    if (images[rowIndex]) {
      icon = images[rowIndex]
    } else {
      return +rowIndex + 1
    }
    return icon !== '' && icon
  }
  const WinRenderer = (props) => {
    const data = props.data
    return <div className="text-sm font-medium text-[#12B76A]">{data.won}</div>
  }

  const LeaderNameRenderer = (props) => {
    const data = props.data
    return (
      <div className="text-sm flex space-x-2 font-medium w-[120px] capitalize">
        {data?.hideLeaderboard ? (
          <>
            <AnonUser className="relative shrink-0 md:w-[40px] md:h-[40px] h-[30px] w-[30px] object-cover self-center mr-3 rounded-full bg-[#98A2B3] p-2" />
            <p className="self-center">Hidden</p>
          </>
        ) : (
          <>
            <CustomImage
              cls="object-cover relative shrink-0 md:w-[40px] md:h-[40px] h-[30px] w-[30px] rounded-full self-center"
              src={
                data?.profileUrl
                  ? data?.profileUrl
                  : '/img/blank-profil-picture.webp'
              }
            />
            <p className="self-center">
              {data.player_name ? data.player_name : 'Hidden'}
            </p>
          </>
        )}
      </div>
    )
  }
  const LostRenderer = (props) => {
    const data = props.data
    return <div className="text-sm font-medium">{data.lose}</div>
  }
  const ResultRenderer = (props) => {
    const data = props.data
    return (
      <>
        {/* <p className="mt-[2px] text-sm font-medium mr-4">
          ${Number(data.totalWonAmount).toFixed(2)}
        </p> */}
        <div className="bg-[#12b76a1a] text-[#12B76A] w-[55px] h-[20px] text-xs text-center rounded-[20px] self-center ">
          <p className="mt-[2px]">{Number(data.returnPercent).toFixed(2)}%</p>
        </div>
      </>
    )
  }
  const BestBetRenderer = (props) => {
    const { winAmount } = props.data.bestBet
    const { usdToBsv, bsvToUsd } = props

    const bestBetHandler = () => {
      const betData = props.data?.bestBet?.bet
      if (betData) {
        setModelData(betData)
        setSlipModelOpen(true)
      }
    }
    return (
      <button onClick={bestBetHandler} className="text-sm font-medium">
        $
        {winAmount
          ? bsvToUsd(Number(winAmount)) > 1
            ? bsvToUsd(Number(winAmount)).toFixed(2)
            : bsvToUsd(Number(winAmount)).toFixed(6)
          : '0.00'}
      </button>
    )
  }

  const columnDefs = [
    {
      headerName: `Rank`,
      defaultHeaderName: 'Rank',
      field: 'Rank',
      suppressMovable: true,
      cellRenderer: RankRenderer,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      headerName: 'Leaders Name',
      field: 'player_name',
      suppressMovable: true,
      cellRenderer: LeaderNameRenderer,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      headerName: 'Best Bet',
      field: 'bestBet',
      suppressMovable: true,
      cellRenderer: (props) => (
        <BestBetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      minWidth: 100,
      maxWidth: 200,
    },
    {
      headerName: 'Won',
      field: 'won',
      suppressMovable: true,
      cellRenderer: WinRenderer,
      minWidth: 150,
      maxWidth: 300,
    },
    {
      headerName: 'Lost',
      field: 'lose',
      suppressMovable: true,
      cellRenderer: LostRenderer,
      minWidth: 130,
      maxWidth: 260,
    },
    {
      headerName: 'ROI',
      field: 'aggregateReturnPercent',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      minWidth: 150,
      maxWidth: 300,
      sort: 'desc',
    },
  ]
  const defaultColDef = {
    sortable: false,
  }

  return (
    <div
      className={`h-[500px] pb-[0.5] mx-auto my-8 ${
        isHome === true ? 'w-[100%]' : 'w-[95%] md:w-[97%] lg:max-w-7xl'
      } `}
      id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          gridOptions={{
            getRowHeight: () => {
              return 70
            },
            defaultColDef: {
              sortable: false,
            },
          }}
          rowData={leaderInfo.length !== 0 ? leaderInfo : userDetails}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // getRowHeight={getRowHeight}
          groupDefaultExpanded={1}
          animateRows={true}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; margin-top:10vh">New week has begun! Waiting for Game Results. Check back later to see where you rank!</span>'
          }
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
      {/* <FormattedMessage id='eaderBoard.footer.message' defaultMessage="New week has begun! Waiting for Game Results. Check back later to see where you rank!" /> */}
    </div>
  )
}

export default LeaderBoardTable
