import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ModuleRegistry } from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { calculateCoefficient, numberSort } from './data'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { getTeamAbbreviation } from '@/constants/countries-leagues'
import { useMediaQuery } from 'react-responsive'
import authSelector from '@/redux/selectors/auth'
import { useIntl } from 'react-intl'
import {
  BetRenderer,
  BetIdRenderer,
  SportRenderer,
  ResultRenderer,
  MatchRenderer,
  CoefRenderer,
  DateRenderer,
  WinRenderer,
  PaymentStatusRenderer,
  SportMobileRenderer,
} from './tableComponents'
import useExchangeRates from '@/hooks/useExchangeRates'
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid'

// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule])

const DropDownRenderer = (props) => {
  const { data } = props

  if (!data.betSlips) {
    return <></>
  }

  return (
    <div className="px-3 py-4 text-sm cursor-pointer relative" align="center">
      {data.collapsed === false ? (
        <ArrowUpIcon className="w-5 h-5 text-gray-300" />
      ) : (
        <ArrowDownIcon className="w-5 h-5 text-gray-300" />
      )}
    </div>
  )
}

const BetsTable = ({
  onIdPress,
  isHome = false,
  betSnapShotRef,
  isMyBets,
  allBets: placedBets,
}) => {
  const gridStyle = { height: '100%', width: '100%', boxSizing: 'border-box' }
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { bsvToUsd, usdToBsv } = useExchangeRates()
  const { user, isAuthenticated } = useSelector(authSelector)
  const router = useRouter()
  const isAdmin = user?.roleType === 'admin'
  const isAdminBets =
    router.pathname.includes('bets') && isAdmin && isAuthenticated === true
  const [detected, setDetected] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const intl = useIntl()

  const gridRef = useRef(null)

  const onCellClicked = ({ data }) => {
    onIdPress(data)
  }
  const [allBets, setAllBets] = useState([])
  useEffect(() => {
    if (gridRef.current && gridRef.current.api && collapse === false) {
      const newBets = placedBets.map((all) => {
        const obj = { ...all }
        obj['rowHeight'] = 65
        if (all.betSlips) {
          obj['collapsed'] = true
        }
        return obj
      })

      const prevBets = []
      gridRef.current.api.forEachNode(function (node) {
        prevBets.push(node.data)
      })
      const uniques = newBets
        .concat(prevBets)
        .filter(
          (item) =>
            !newBets.some((i) => i.betID === item.betID) ||
            !prevBets.some((i) => i.betID === item.betID),
        )

      uniques.sort((a, b) => {
        return a.checked_date - b.checked_date
      })
      gridRef.current.api.applyTransaction({
        add: uniques,
        addIndex: gridRef.current.api.getLastDisplayedRowIndex() + 1,
      })
    }
  }, [placedBets])

  useEffect(() => {
    const mapped = placedBets.map((all) => {
      const obj = { ...all }
      obj['rowHeight'] = 65
      if (all.betSlips) {
        obj['collapsed'] = true
      }
      return obj
    })
    setAllBets(mapped)
  }, [placedBets])

  const sortModel = [
    { colId: 'placedAt', sort: 'asc' }, // Specify the column ID and sort order
  ]

  const columnDefs = [
    {
      headerName: intl.formatMessage({
        id: 'table.betId',
        defaultMessage: 'Bet ID',
      }),
      field: 'betID',
      suppressMovable: true,
      cellRenderer: BetIdRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 100,
      valueGetter: (params) => {
        const { data } = params
        if (data.betID) {
          return data.betID.toLowerCase()
        }
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.sport',
        defaultMessage: 'Sport',
      }),
      field: 'match.sport',
      suppressMovable: true,
      cellRenderer: SportRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 170,
      valueGetter: (params) => {
        const { data } = params
        let sport = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          sport = data.betSlips[0]?.match.sport
        } else {
          sport = data?.match?.sport
        }
        return sport
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.result',
        defaultMessage: 'Result',
      }),
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let value = ''
        if (data.status === 'active') {
          value = 'Active'
        } else {
          if (data?.grade === 'win') {
            value = 'Win'
          } else {
            if (data?.grade === 'refund') {
              value = 'Push'
            } else {
              value = 'Lose'
            }
          }
        }
        return value.toLowerCase()
      },
      minWidth: 110,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.match',
        defaultMessage: 'Match',
      }),
      field: 'match.home_team',
      suppressMovable: true,
      cellRenderer: MatchRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let match = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          match = data.betSlips[0]?.match.home_team
        } else {
          match = data?.match?.home_team
        }
        return match
      },
      onCellClicked: onCellClicked,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.bet',
        defaultMessage: 'Bet',
      }),
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.date',
        defaultMessage: 'Date/Time',
      }),
      field: 'placedAt',
      suppressMovable: true,
      cellRenderer: DateRenderer,

      valueGetter: (params) => {
        const { data } = params
        let placedAt = data.placedAt
        //return formatDate(placedAt, 'MM/DD/YYYY')
        return new Date(placedAt).getTime()
      },
      onCellClicked: onCellClicked,
      sortable: false,
      // sortingOrder: ['asc', 'desc'],
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.win',
        defaultMessage: 'Win',
      }),
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <WinRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
    {
      headerName: intl.formatMessage({
        id: 'table.coef',
        defaultMessage: 'Coef',
      }),
      field: 'price',
      suppressMovable: true,
      cellRenderer: CoefRenderer,
      comparator: numberSort,
      valueGetter: (params) => {
        const { data } = params
        return calculateCoefficient(data.price)
      },
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 130,
    },
    {
      headerName: '',
      field: 'notes',
      suppressMovable: true,
      cellRenderer: DropDownRenderer,
      minWidth: 120,
      onCellClicked: (params) => {
        const { data, rowIndex } = params
        if (!data.betSlips) {
          onIdPress(params?.data)
          return
        }
        const api = gridRef.current.api
        if (data.collapsed === true) {
          data.collapsed = false
          const arr = []
          data?.betSlips?.forEach((bet, i) => {
            const obj = { ...bet }
            obj['rowHeight'] = 60
            obj['betPrice'] = ''
            obj['price'] = ''
            obj['betID'] = ''
            obj['status'] = ''
            obj['placedAt'] = `${data.placedAt}`
            obj['id'] = `bet-${data.betID}-${i}`
            arr.push(obj)
          })

          api.applyTransaction({
            add: arr,
            addIndex: rowIndex + 1,
          })
          api.applyTransaction({
            update: [data],
          })
        } else {
          const arr = []
          const nodes = api.getRenderedNodes()

          data.betSlips.forEach((bet, i) => {
            const fill = nodes.filter((node) => {
              const nodeId = node.data.id
              const betId = `bet-${data.betID}-${i}`
              if (nodeId === betId) {
                return bet
              }
            })
            arr.push(fill[0]?.data)
          })

          data.collapsed = true

          api.applyTransaction({
            update: [data],
          })
          api.applyTransaction({
            remove: arr,
          })
        }
        setDetected(!detected)
        setCollapse(true)
      },
    },
  ]
  const paymentStatusColumn = {
    headerName: 'Payment Status',
    field: 'status',
    suppressMovable: true,
    cellRenderer: PaymentStatusRenderer,
    sortable: false,
    unSortIcon: true,
    valueGetter: (params) => {
      const { data } = params
      let value = ''
      if (data.status === 'active') {
        value = 'Active'
      } else if (data?.status === 'completed') {
        value = 'Completed'
      } else {
        value = 'NA'
      }
      return value.toLowerCase()
    },
    minWidth: 110,
  }
  const adminColumns =
    isAdmin && router.pathname.includes('admin/bets')
      ? [paymentStatusColumn]
      : []
  const columnDefinitions = [...columnDefs, ...adminColumns]
  const columnMobile = [
    {
      headerName: 'Event',
      field: 'match',
      suppressMovable: true,
      cellRenderer: SportMobileRenderer,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
      valueGetter: (params) => {
        const { data } = params
        let match = ''
        let filterText = ''
        if (data?.betSlips && data?.betSlips[0] && data.betSlips[0]?.match) {
          match = data.betSlips[0]?.match.home_team
          filterText = getTeamAbbreviation(
            data.betSlips[0]?.match.league,
            match,
          )
        } else {
          match = data?.match?.home_team
          filterText = getTeamAbbreviation(data?.match?.league, match)
        }
        return `${match} ${filterText}`
      },
      onCellClicked: onCellClicked,
    },
    {
      headerName: 'Result',
      field: 'status',
      suppressMovable: true,
      cellRenderer: ResultRenderer,
      sortable: false,
      unSortIcon: true,
      valueGetter: (params) => {
        const { data } = params
        let value = ''
        if (data.status === 'active') {
          value = 'Active'
        } else {
          if (data?.grade === 'win') {
            value = 'Win'
          } else {
            if (data?.grade === 'refund') {
              value = 'Push'
            } else {
              value = 'Lose'
            }
          }
        }
        return value.toLowerCase()
      },
      onCellClicked: onCellClicked,

      minWidth: 150,
    },
    {
      headerName: 'Bet Amount',
      field: 'betPrice',
      suppressMovable: true,
      cellRenderer: (props) => (
        <BetRenderer usdToBsv={usdToBsv} bsvToUsd={bsvToUsd} {...props} />
      ),
      onCellClicked: onCellClicked,
      sortable: false,
      unSortIcon: true,
      minWidth: 150,
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      sortable: false,
    }
  }, [])

  const getRowHeight = useCallback((params) => {
    return params.data.rowHeight
  }, [])

  return (
    <div
      className={`${
        isAdminBets || isMyBets ? 'h-[500px]' : 'h-[720px]'
      }  pb-[0.5] mx-auto my-8  ${
        isHome === true ? 'w-[100%]' : 'w-[95%] 2xl:w-[97%]'
      }`}
      // id="grid-wrapper"
    >
      <div style={gridStyle} className="ag-theme-quartz-dark">
        <AgGridReact
          ref={gridRef}
          rowData={allBets}
          columnDefs={sm ? columnMobile : columnDefinitions}
          defaultColDef={defaultColDef}
          getRowHeight={getRowHeight}
          groupDefaultExpanded={1}
          animateRows={true}
          sortModel={sortModel}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444;">No Results Found</span>'
          }
          suppressScrollOnNewData
          onFirstDataRendered={(params) => {
            params.api.sizeColumnsToFit()
          }}
        />
      </div>
    </div>
  )
}

export default BetsTable
