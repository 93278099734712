import useExchangeRates from '@/hooks/useExchangeRates'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { gridRef as casinoRef } from './AgGridCasino'
import TableGrid from './AgGridTable'
import InfiniteTableGrid from './AgGridInfiniteScroll'
import BetSlipsModel from './betSlipsModel'
import FormInput from '@/components/ui/forms/form-input'
import { NumberChip } from '@/utils/helper'
import debounce from 'lodash/debounce'
import {
  fetchEvents,
  firebaseGetBetById,
  firebaseGetLatestPlinkoGamesSortedByDate,
  firebaseGetOwnPlinkoHistory,
  getPredictionBets,
} from '@/firebase/utils'
import LeaderBoardTable from '../leaderboard/AgGridLeaderboard'
import { useMediaQuery } from 'react-responsive'
import BetCasinoModal from '../plinko/game/components/BetModal'
import CasinoTableGrid from '../plinko/game/components/Tables'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'
import { FormattedMessage, useIntl } from 'react-intl'
import PredictionBetsTable from './AgGridPredictionTable'
import PredictionModal from '../prediction-market/User/predictionEventDetail/predictionModal'
import { Casino, GamesHeader, PredictionIcon } from '@/icons/index'
import { SearchIcon } from '@/icons/index'

const statusColors = {
  active: 'text-[#F7941D] md:bg-[#F7941D]/20',
  win: ' text-[#12B76A] md:bg-[#12B76A]/10',
  lose: ' text-[#F04438] md:bg-[#F04438]/10',
}
const statusColorsMobile = {
  active: 'text-[#F7941D]',
  win: 'text-[#12B76A]',
  lose: 'text-[#F04438]',
}

const StatusCard = ({
  className = '',
  status,
  BSV,
  totalBets,
  totalAmount,
  ...rest
}) => {
  return (
    <div
      className={`md:h-[160px] h-[100px] bg-white/5 rounded-xl border-opacity-20 border border-white/20 flex md:flex-col flex-row md:justify-around justify-between cursor-pointer px-8 ${className}`}
      {...rest}
    >
      <div className="flex flex-col justify-center">
        <div
          className={`md:w-fit justify-center items-center rounded-2xl md:py-1 md:px-3 text-sm ${
            statusColorsMobile[status?.toLowerCase()]
          } font-medium md:${statusColors[status?.toLowerCase()]}`}
        >
          {status}
        </div>
        <div className="inline-block text-sm font-medium text-[#888B8B] md:mt-5">
          {totalBets} Bets
        </div>
      </div>
      <div className="flex md:flex-row flex-col flex-wrap items-center gap-2 md:justify-between justify-center">
        <div className="font-bold lg:text-xl sm:text-base">${totalAmount}</div>
        <NumberChip number={BSV + ' ' + 'BSV'} className="text-[#FDB022]" />
      </div>
    </div>
  )
}

export default function Bets({
  showRightSidebar,
  asComponent = false,
  betTypes = 'sports',
  isHomeLayout = false,
}) {
  const router = useRouter()
  const intl = useIntl()
  const { user, isAuthenticated } = useSelector(authSelector)
  const isAdmin = user?.roleType === 'admin'

  const isMyBets =
    router.pathname.includes('bets') && !isAdmin && isAuthenticated
  const { placedBets, betslipsOpenMobile, placedLiveGames } =
    useSelector(betSlipsSelector)
  const [allBets, setAllBets] = useState([])
  const currentPlacedBets =
    isHomeLayout || !user
      ? placedLiveGames
      : router.pathname === '/admin/bets'
      ? allBets
      : placedBets
  const [activeBetsType, setActiveBetsType] = useState(betTypes)
  const [plinkoHistory, setPlinkoHistory] = useState(null)
  const [slipModelOpen, setSlipModelOpen] = useState(false)
  const [predictionModal, setPredictionModal] = useState(false)
  const [modelData, setModelData] = useState(null)
  const [predictionModalData, setPredictionModalData] = useState({
    data: null,
    title: null,
    expiryDate: null,
  })
  const [leaderInfo, setLeaderInfo] = useState([])
  const [message, setMessage] = useState('')
  const [nextBetIndex, setNextBetIndex] = useState(-1)
  const [prevBetIndex, setPrevBetIndex] = useState(-1)
  const [modalCasinoOpen, setModalCasinoOpen] = useState(false)
  const [casinoData, setCasinoData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [searchedData, setSearchedData] = useState([])
  const [searchBet, setSearchBet] = useState('')
  const [isLive, setIsLive] = useState(false)
  const [betId, setBetId] = useState('')
  const [predictionBetsEvents, setPredictionBetsEvents] = useState({
    bets: null,
    events: null,
  })
  const [totalCounts, setTotalCounts] = useState({
    totalWon: 0,
    totalLost: 0,
    totalActive: 0,
    activeBets: 0,
    wonBets: 0,
    lossBets: 0,
  })

  const { userDetails } = useSelector((state) => state.leaderBoard)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  let betSnapShotRef = useRef(null)

  const { bsvToUsd, usdToBsv } = useExchangeRates()
  useEffect(() => {
    if (isMyBets) {
      firebaseGetOwnPlinkoHistory(user?.uid).then((data) => {
        if (data?.historyArray) {
          setPlinkoHistory(() => data.historyArray)
        } else {
          setPlinkoHistory([])
        }
      })
    } else {
      firebaseGetLatestPlinkoGamesSortedByDate(20).then((data) => {
        if (data) {
          setPlinkoHistory(() => [...data])
        }
      })
    }
  }, [])

  const changeHandlerSports = useCallback(
    debounce((e) => {
      setSearchBet(e.target.value)
      const filteredBets = bets.filter((bet) =>
        bet.betID.toLowerCase().includes(e.target.value?.toLowerCase()),
      )
      setSearchedData(filteredBets)
    }, 400), // Adjust the debounce delay (in milliseconds) as needed
    [],
  )

  const changeHandlerCasino = useCallback((e) => {
    if (casinoRef && casinoRef.current) {
      casinoRef.current.api.setQuickFilter(e.target.value)
      casinoRef.current.api.hideOverlay()
      if (casinoRef.current.api.rowRenderer.lastRenderedRow == -1) {
        casinoRef.current.api.showNoRowsOverlay()
      }
    }
  }, [])

  const closeSlipsModel = () => {
    setSlipModelOpen(false)
    setPredictionModal(false)
    setPredictionModalData({
      data: null,
      title: null,
      expiryDate: null,
    })
    setModelData(null)
  }

  // useEffect(() => {
  //   dispatch(leaderBoardBetData())
  // }, [])

  const inputHandler = (e) => {
    //convert input text to lower case
    const results = userDetails?.filter((res) => {
      if (e.target.value == '') return []
      return res.player_name
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    })
    if (results.length !== 0) {
      setLeaderInfo(results)
      setMessage('')
    } else {
      setMessage('Player not exist')
    }
  }

  const onBetIdPress = useCallback(
    (data) => {
      const ind = currentPlacedBets.findIndex((bet) => bet.betID === data.betID)
      if (ind > -1) {
        setNextBetIndex(ind + 1)
        setPrevBetIndex(ind - 1)
        setModelData(data)
        setSlipModelOpen(true)
        setIsLive(data?.is_live)
      }
    },
    [currentPlacedBets],
  )

  const handleSearchIconClick = async () => {
    const betInfo = await firebaseGetBetById(betId)
    setModelData(betInfo.bet)
    setTimeout(() => {
      setSlipModelOpen(true)
    }, 500)
  }

  const setBetIdHandle = (event) => {
    setBetId(event.target.value)
  }

  const onPressNext = (index) => {
    const dt = currentPlacedBets[index]
    if (dt) {
      setModelData(dt)
      setPrevBetIndex(index - 1)
      setNextBetIndex(index + 1)
    } else {
      if (user?.uid && router.pathname.includes('bets')) {
        setLoader(true)

        setPrevBetIndex(index - 1)
        setNextBetIndex(index + 1)
        setTimeout(() => {
          setLoader(false)
        }, 500)
      }
    }
  }

  const onPressPrev = (index) => {
    const dt = currentPlacedBets[index]
    if (dt) {
      setModelData(dt)
      setPrevBetIndex(index - 1)
      setNextBetIndex(index + 1)
    }
  }

  const bets = currentPlacedBets.map((all) => {
    const obj = { ...all }
    obj['rowHeight'] = 65
    if (all.betSlips) {
      obj['collapsed'] = true
    }
    return obj
  })

  const onPlinkoBetIdPress = useCallback(
    (data) => {
      const ind = plinkoHistory.findIndex((bet) => bet.betID === data.betID)
      if (ind > -1) {
        setCasinoData(data)
        setModalCasinoOpen(true)
      }
    },
    [plinkoHistory],
  )

  const closeCasinoModal = () => {
    setModalCasinoOpen(false)
    setCasinoData(null)
  }

  useEffect(() => {
    if (currentPlacedBets.length > 0) {
      const filteredBets = currentPlacedBets.reduce(
        (acc, curr) => {
          if (curr.betType === 'multi') {
            if (curr.status === 'active') {
              acc.activeBets += 1
              acc.totalActive += Number(curr.price)
            } else if (curr.grade === 'win') {
              acc.wonBets += 1
              acc.totalWon += Number(curr.possibleWinPrice)
            } else {
              acc.lossBets += 1
              acc.totalLost += Number(curr.price)
            }
          } else {
            if (curr.status === 'active') {
              acc.activeBets += 1
              acc.totalActive += Number(curr.betPrice)
            } else if (curr.grade === 'win') {
              acc.wonBets += 1
              acc.totalWon +=
                Number(curr.price) > 0
                  ? Number(curr.betPrice) * (Number(curr.price) / 100)
                  : Number(curr.betPrice) * (100 / Math.abs(Number(curr.price)))
            } else {
              acc.lossBets += 1
              acc.totalLost += Number(curr.betPrice)
            }
          }

          return acc
        },
        {
          totalWon: 0,
          totalLost: 0,
          totalActive: 0,
          activeBets: 0,
          wonBets: 0,
          lossBets: 0,
        },
      )
      setTotalCounts(filteredBets)
    }
  }, [currentPlacedBets])

  const getPredictionBetsWithEvents = async (uid) => {
    const bets = await getPredictionBets(uid)
    const events = await fetchEvents()
    if (bets && events) {
      const eventsWithKeys = events.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: { ...cur } }),
        [],
      )
      setPredictionBetsEvents({
        bets,
        events: eventsWithKeys,
      })
    } else {
      setPredictionBetsEvents({
        bets: [],
        events: [],
      })
    }
  }

  useEffect(() => {
    if (activeBetsType === 'prediction') {
      getPredictionBetsWithEvents(user?.uid)
    }
  }, [activeBetsType, user])

  const onPredictionBetPress = (data, expiryDate, title) => {
    setPredictionModalData({ data, expiryDate, title })
    setPredictionModal(true)
  }

  return (
    <>
      <div
        className={`mt-8 ${
          showRightSidebar ? 'lg:w-[calc(100%-20rem)]' : 'lg:w-full'
        }`}
      >
        <div
          className={`items-start md:items-center mx-0 ${
            asComponent ? 'mt-4 border-t-2 border-white/5 pt-4' : ''
          } ${
            isHomeLayout === false
              ? 'mx-8 grid gap-4 grid-cols-1 md:grid-cols-3'
              : ''
          } `}
        >
          {isHomeLayout === false && (
            <>
              {user?.uid && router.pathname.includes('bets') && !isAdmin ? (
                <div className="flex flex-row items-center gap-2">
                  <h3 className="text-xl font-semibold font-sans">
                    <FormattedMessage
                      id="navLeftBar.myBets"
                      defaultMessage="My Bets"
                    />
                  </h3>
                  <NumberChip
                    number={currentPlacedBets.length}
                    className="bg-[#008000]/20"
                  />
                </div>
              ) : (
                <div className=" w-full ">
                  <h3 className="text-xl font-semibold font-sans">
                    <FormattedMessage
                      id="liveBets.title"
                      defaultMessage="Live Bets"
                    />
                  </h3>
                </div>
              )}
            </>
          )}

          {!asComponent && (
            <>
              {isHomeLayout === false ? (
                <>
                  <div className="flex items-center ">
                    <div className="mx-auto rounded-full bg-white/5 p-2 flex justify-center gap-2 items-center flex-wrap">
                      <div
                        className={`pr-4 pl-2 py-1.5 flex items-center gap-1 flex-row rounded-full cursor-pointer relative ${
                          activeBetsType === 'sports'
                            ? 'green-mouse-pointer'
                            : 'hover:bg-white/5 duration-300'
                        }`}
                        onClick={() => setActiveBetsType('sports')}
                      >
                        <GamesHeader className="w-5 h-5" />
                        <span className="font-sans font-medium text-sm text-center text-white">
                          <FormattedMessage
                            id="navbar.sports"
                            defaultMessage="Sports"
                          />
                        </span>
                      </div>
                      <div
                        className={`px-2.5 py-1.5 flex items-center gap-1 flex-row rounded-full cursor-pointer relative ${
                          activeBetsType === 'casino'
                            ? 'green-mouse-pointer'
                            : 'hover:bg-white/5 duration-300'
                        }`}
                        onClick={() => setActiveBetsType('casino')}
                      >
                        <Casino className="w-5 h-5" />
                        <span className="font-sans font-medium text-sm text-center text-white">
                          <FormattedMessage
                            id="navbar.casino"
                            defaultMessage="Sports"
                          />
                        </span>
                      </div>

                      <div
                        className={`px-2.5 py-1.5 flex items-center gap-1 flex-row rounded-full cursor-pointer relative ${
                          activeBetsType === 'prediction'
                            ? 'green-mouse-pointer'
                            : 'hover:bg-white/5 duration-300'
                        }`}
                        onClick={() => setActiveBetsType('prediction')}
                      >
                        <PredictionIcon className="w-5 h-5" />
                        <span className="font-sans font-medium text-sm text-center text-white">
                          Prediction
                        </span>
                      </div>
                    </div>
                  </div>
                  {user && (
                    <FormInput
                      showBorder={false}
                      placeholder={intl.formatMessage({
                        id: 'global.search',
                        defaultMessage: 'Search',
                      })}
                      className={`flex-1 hidden md:flex ${
                        sm && betslipsOpenMobile && 'hidden'
                      }`}
                      icon={<SearchIcon className="w-5 h-5" />}
                      onIconClick={
                        isAdmin && activeBetsType === 'sports'
                          ? handleSearchIconClick
                          : undefined
                      }
                      onChange={
                        !isAdmin
                          ? activeBetsType === 'sports'
                            ? changeHandlerSports
                            : changeHandlerCasino
                          : setBetIdHandle
                      }
                      id="search-bets-box"
                    />
                  )}
                </>
              ) : (
                <div className="bg-[#fff]/5 px-2 py-4 w-full rounded-2xl flex flex-row items-center gap-2">
                  <div
                    className={`px-3.5 py-2.5 w-[50%] flex justify-center items-center gap-1 flex-row rounded-lg cursor-pointer relative ${
                      activeBetsType === 'sports'
                        ? 'bg-white/10'
                        : 'hover:bg-white/5 duration-300'
                    }`}
                    onClick={() => setActiveBetsType('sports')}
                  >
                    <span className="font-sans font-medium text-sm text-center text-white">
                      <FormattedMessage
                        id="liveBets.sportsBets"
                        defaultMessage="Sport Bets"
                      />
                    </span>
                  </div>
                  <div
                    className={`px-3.5 py-2.5 w-[50%] flex justify-center items-center gap-1 flex-row rounded-lg cursor-pointer relative ${
                      activeBetsType === 'casino'
                        ? 'bg-white/10'
                        : 'hover:bg-white/5 duration-300'
                    }`}
                    onClick={() => setActiveBetsType('casino')}
                  >
                    <span className="font-sans font-medium text-sm text-center text-white">
                      <FormattedMessage
                        id="liveBets.casinoBets"
                        defaultMessage="Casino Bets"
                      />
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {/* Cards Section */}
        {user?.uid &&
          isHomeLayout === false &&
          router.pathname.includes('bets') &&
          !isAdmin && (
            <div className="grid col-span-1 mt-8 gap-4 md:grid-cols-4 px-4 sm:px-6 lg:px-8">
              <StatusCard
                className="md:col-span-2"
                status="Active"
                BSV={totalCounts.totalActive.toFixed(7)}
                totalBets={totalCounts.activeBets}
                totalAmount={bsvToUsd(totalCounts.totalActive).toFixed(7)}
              />
              <StatusCard
                status="Win"
                BSV={totalCounts.totalWon.toFixed(7)}
                totalBets={totalCounts.wonBets}
                totalAmount={bsvToUsd(totalCounts.totalWon).toFixed(7)}
              />

              <StatusCard
                status="Lose"
                BSV={totalCounts.totalLost.toFixed(7)}
                totalBets={totalCounts.lossBets}
                totalAmount={bsvToUsd(totalCounts.totalLost).toFixed(7)}
              />
            </div>
          )}

        {(isHomeLayout === false && isAdmin) ||
          (isHomeLayout === false && isMyBets && (
            <div
              className={`w-[100%] visible md:invisible flex justify-center -mb-3 mt-4 ${
                sm && betslipsOpenMobile && 'hidden'
              }`}
            >
              <div className="w-[95%] ">
                <FormInput
                  showBorder={false}
                  placeholder={intl.formatMessage({
                    id: 'global.search',
                    defaultMessage: 'Search',
                  })}
                  className="flex-1 w-[100%]"
                  icon={<SearchIcon className="w-5 h-5" />}
                  onChange={
                    activeBetsType === 'sports'
                      ? changeHandlerSports
                      : changeHandlerCasino
                  }
                  id="search-bets-box"
                />
              </div>
            </div>
          ))}

        {activeBetsType === 'casino' ? (
          <>
            <CasinoTableGrid
              allBets={plinkoHistory}
              bsvToUsd={bsvToUsd}
              isHome={isHomeLayout}
              onIdPress={onPlinkoBetIdPress}
            />
          </>
        ) : activeBetsType === 'leaderboard' && isHomeLayout === true ? (
          <>
            <LeaderBoardTable
              leaderInfo={leaderInfo}
              userDetails={userDetails}
              message={message}
              inputHandler={inputHandler}
              isHome={isHomeLayout}
            />
          </>
        ) : activeBetsType === 'prediction' ? (
          <PredictionBetsTable
            betsData={predictionBetsEvents}
            onBetIdPress={onPredictionBetPress}
            user={user}
          />
        ) : (
          <>
            {router.pathname === '/admin/bets' ? (
              <InfiniteTableGrid
                allBets={searchBet ? searchedData : bets}
                setAllBets={setAllBets}
                onIdPress={onBetIdPress}
                totalBets={currentPlacedBets.length}
                isHome={isHomeLayout}
                betSnapShotRef={betSnapShotRef}
                isMyBets={isMyBets}
                isLive={isLive}
              />
            ) : currentPlacedBets?.length ? (
              <TableGrid
                allBets={searchBet ? searchedData : bets}
                onIdPress={onBetIdPress}
                totalBets={currentPlacedBets.length}
                isHome={isHomeLayout}
                betSnapShotRef={betSnapShotRef}
                isMyBets={isMyBets}
                isLive={isLive}
              />
            ) : (
              <div className="w-full h-[calc(100vh-300px)]">
                <div className="w-full h-full flex items-center justify-center">
                  <h3 className="font-bold text-lg">
                    {' '}
                    <FormattedMessage
                      id="no.bets.found"
                      defaultMessage="No Bets Found"
                    />
                  </h3>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <BetSlipsModel
        isOpen={slipModelOpen}
        onClose={closeSlipsModel}
        data={modelData}
        nextBetIndex={nextBetIndex}
        prevBetIndex={prevBetIndex}
        onPressNext={onPressNext}
        loader={loader}
        onPressPrev={onPressPrev}
        isLive={isLive}
      />

      {casinoData && (
        <BetCasinoModal
          isOpen={modalCasinoOpen}
          onClose={closeCasinoModal}
          data={casinoData}
          bsvToUsd={bsvToUsd}
          usdToBsv={usdToBsv}
        />
      )}

      {predictionModalData.data && (
        <PredictionModal
          isOpen={predictionModal}
          onClose={closeSlipsModel}
          expiryDate={predictionModalData.expiryDate}
          data={predictionModalData.data}
          title={predictionModalData.title}
        />
      )}
    </>
  )
}
